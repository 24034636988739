exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-contentful-aviso-legal-slug-js": () => import("./../../../src/pages/aviso-legal/{contentfulAvisoLegal.slug}.js" /* webpackChunkName: "component---src-pages-aviso-legal-contentful-aviso-legal-slug-js" */),
  "component---src-pages-aviso-legal-index-js": () => import("./../../../src/pages/aviso-legal/index.js" /* webpackChunkName: "component---src-pages-aviso-legal-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-casos-de-uso-index-js": () => import("./../../../src/pages/casos-de-uso/index.js" /* webpackChunkName: "component---src-pages-casos-de-uso-index-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cx-nuevo-cliente-js": () => import("./../../../src/pages/cx/nuevo-cliente.js" /* webpackChunkName: "component---src-pages-cx-nuevo-cliente-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-por-que-js": () => import("./../../../src/pages/por-que.js" /* webpackChunkName: "component---src-pages-por-que-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-servicios-desarrollo-web-js": () => import("./../../../src/pages/servicios/desarrollo-web.js" /* webpackChunkName: "component---src-pages-servicios-desarrollo-web-js" */),
  "component---src-pages-servicios-email-marketing-js": () => import("./../../../src/pages/servicios/email-marketing.js" /* webpackChunkName: "component---src-pages-servicios-email-marketing-js" */),
  "component---src-pages-servicios-index-js": () => import("./../../../src/pages/servicios/index.js" /* webpackChunkName: "component---src-pages-servicios-index-js" */),
  "component---src-pages-servicios-publicidad-digital-js": () => import("./../../../src/pages/servicios/publicidad-digital.js" /* webpackChunkName: "component---src-pages-servicios-publicidad-digital-js" */)
}

